:root {
  --background: #ffffff;
  --primary: #ee4d2d;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  button {
    -webkit-tap-highlight-color: transparent;
  }
  button:focus {
    outline: 0;
  }
}

@layer utilities {
  @variants responsive {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-size: 16px;
  text-size-adjust: none;
  @apply bg-white font-sans select-none text-black text-opacity-[87%];
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

@font-face {
  font-family: 'Shopee2021';
  src: url('/fonts/Shopee2021-Regular.woff') format('woff'), url('/fonts/Shopee2021-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Shopee2021';
  src: url('/fonts/Shopee2021-Medium.woff') format('woff'), url('/fonts/Shopee2021-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Shopee2021';
  src: url('/fonts/Shopee2021-Bold.woff') format('woff'), url('/fonts/Shopee2021-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
}
